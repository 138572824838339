
<div class="dropzone" 
     dropzone
     (hovered)="toggleHover($event)"
     (dropped)="onDrop($event)"
     [class.hovering]="isHovering">


     <h3>Déplacez un fichier</h3>
     <p>Drag and Drop a File</p>
</div>
<br>
<h3>Uploads</h3>

<div *ngFor="let file of files">
  <app-upload-list [id]="id" [file]="file"></app-upload-list>
</div>

<div *ngFor="let fileB of filesBis">
  <app-upload-list [id]="id" [fileB]="fileB"></app-upload-list>
</div>