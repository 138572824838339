<div class="parallaxPoemHome">
  <div class="titlePoemHome">
    <h1>{{poemHome.title}}</h1>
  </div>
<div class="content">



        <div class="contentSite">
            <div *ngIf="user">
              <div class="modifyer"><mat-slide-toggle [checked]="isCheck" (change)="onChange()">Modifier</mat-slide-toggle></div>
            </div>
          

            <!--AFFICHAGE STANDARD-->
            <div *ngIf="isCheck==false">
        
             
              <pre class="contentCore">{{ poemHome.content }}</pre>
            </div>
            <!--SI MODIFICATION-->
            <div *ngIf="isCheck==true">
        
              <form [formGroup]="poemHomeForm" (ngSubmit)="updatePoemHome()" class="example-form">
                <mat-form-field class="example-full-width titleLimit mat-headline">
                  <mat-label>Titre</mat-label>
                  <input matInput  id="title"  formControlName="title">
                </mat-form-field>
                <br>
                <br>
                <mat-form-field class="example-full-width">
                  <mat-label>Contenu</mat-label>
                  <textarea matInput rows="20" cols="60"  id="content" formControlName="content"></textarea>
                </mat-form-field>
          
                <br>
                <button class="btn btn-success" [disabled]="poemHomeForm.invalid"
                      type="submit">Enregistrer
              </button>
        
              
              </form>
              <br>
        
            </div>  
           
          </div>
          <br><br><br>
</div>
<br><br><br>
</div>
