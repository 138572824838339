<div class="content">
  <mat-card>
<div>
 
    <div>
      <h5>{{ poem.category }}</h5> <div class="modifyer"><mat-slide-toggle [checked]="isCheck" (change)="onChange()">Modifier</mat-slide-toggle></div>
    </div>
  
    <br>

    <!--AFFICHAGE STANDARD-->
    <div *ngIf="isCheck==false" class="padd">
      
      <div *ngIf="poem.audio">
        <div class="audio"> 
        <mat-basic-audio-player [audioUrl]="audioSrc" [title]="msbapTitle" [autoPlay]="false" [startOffset]="startOffset"
        [displayTitle]="msbapDisplayTitle" [displayVolumeControls]="msbapDisplayVolumeControls" ></mat-basic-audio-player>
        </div>
        <br><br>
      </div>

          <h1>{{ poem.title }}</h1>
          
      <div *ngIf="poem.photo">
        <br>
        <img style="max-width:400px;" [src]="poem.photo">
        <br>
      </div>
      
      <br>
      <br>
      <pre class="contentCore">{{ poem.content }}</pre>
      <br>
      <br>
      <button mat-raised-button color="primary" (click)="onBack()">Retour</button> 
      <div class="modifyer">
      <!--<button mat-icon-button  style="color: crimson;" (click)="openDialog(poem)">
        <mat-icon>delete</mat-icon>
      </button>-->
    </div>
    </div>
    <!--SI MODIFICATION-->
    <div *ngIf="isCheck==true">

      <br>
      <h4>Photo</h4>
      <input type="file" (change)="detectFiles($event)"
             class="filePics" accept="image/*">
      <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
      <br>
      <br>
      <button  mat-raised-button color="warn" (click)="rmPics()">Clear Photo </button>
          <br>
          <br>
      <h4>Audio</h4>
      <input type="file" (change)="detectAudio($event)"
             class="filePics" accept="audio/*">
      <p class="text-success" *ngIf="audioUploaded">Fichier chargé !</p>
      <br>
      <br>
      <button  mat-raised-button color="accent" (click)="rmAudio()">Clear Audio </button>
      <br>
      <br>
      <br>
      <form [formGroup]="poemForm" (ngSubmit)="updatePoem()" class="example-form">
        <mat-form-field class="example-full-width titleLimit mat-headline">
          <mat-label>Titre</mat-label>
          <input matInput  id="title"  formControlName="title">
        </mat-form-field>
        <br>
        <br>
        <mat-form-field class="example-full-width">
          <mat-label>Contenu</mat-label>
          <textarea matInput rows="20" cols="60"  id="content" formControlName="content"></textarea>
        </mat-form-field>
  
        <br>
        <button class="btn btn-success" [disabled]="poemForm.invalid"
              type="submit">Enregistrer
      </button>

      
      </form>
      <br>

    </div>
    
   
  </div>

</mat-card>
<br>
<br>
<br>
<br>
</div>


