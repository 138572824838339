
 
  <div class="parallax"></div>
  <div class="titleHome">
    <h1>{{site.title}}</h1>
  </div>
        <div class="contentSite">
          
            <div *ngIf="user">
              <div class="modifyer"><mat-slide-toggle [checked]="isCheck" (change)="onChange()">Modifier</mat-slide-toggle></div>
              <br>
              <br>
            </div>
          

            <!--AFFICHAGE STANDARD-->
            <div *ngIf="isCheck==false">
              <pre class="contentCore">{{ site.content }}</pre>
              <br><br>
            </div>
            <!--SI MODIFICATION-->
            <div *ngIf="isCheck==true">
              
              <form [formGroup]="siteForm" (ngSubmit)="updateSite()" class="example-form">
                <mat-form-field class="example-full-width titleLimit mat-headline">
                  <mat-label>Titre</mat-label>
                  <input matInput  id="title"  formControlName="title">
                </mat-form-field>
                <br>
                <br>
                <mat-form-field class="example-full-width">
                  <mat-label>Contenu</mat-label>
                  <textarea matInput rows="20" cols="60"  id="content" formControlName="content"></textarea>
                </mat-form-field>
          
                <br>
                <button class="btn btn-success" [disabled]="siteForm.invalid"
                      type="submit">Enregistrer
              </button>
        
              
              </form>
              <br>
        
            </div>
            
           
          </div>
          <div class="parallax"></div>
    
<!--
<form role="form" #myForm="ngForm" accept-charset="UTF-8" novalidate>
  <div class="form-group has-feedback" 
        [ngClass]="{ 'has-error': myckeditor.invalid && myckeditor.touched }">
    <ckeditor [(ngModel)]="mycontent" 
              #myckeditor="ngModel"
              name="myckeditor"
              required
              [config]="ckeConfig" 
              debounce="500" 
              (paste)="onPaste($event)"
              (change)="onNChange($event)">
    </ckeditor>
    <div *ngIf="myckeditor.invalid && myckeditor.touched" class="help-block">Required field.</div>
  </div>
  <button [disabled]="myForm.invalid" class="btn btn-primary">Save</button>
  </form>
  <div [innerHTML]="mycontent"></div>
  -->