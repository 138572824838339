<mat-toolbar color="primary">
  <span routerLink="/home" routerLinkActive="active-link">Home</span>
  <span routerLink="/poemHome" routerLinkActive="active-link">Poèmes</span>
  <span routerLink="/poem" routerLinkActive="active-link">Liste de Poèmes</span>
  <span routerLink="/other" routerLinkActive="active-link">Proses</span>
  <span routerLink="/skillHome" routerLinkActive="active-link">Skills</span>
  <span routerLink="/skillList" routerLinkActive="active-link">Liste de Skills</span>
  <span routerLink="/contact" routerLinkActive="active-link">Contact</span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>

  
  <span *ngIf="!isConnected" routerLink="/login" routerLinkActive="active-link">Log In </span>
  <span *ngIf="isConnected" routerLink="/addPoem" routerLinkActive="active-link">Ajouter Poème </span>
  <span *ngIf="isConnected" routerLink="/addSkill" routerLinkActive="active-link">Ajouter Skill</span>&nbsp;
  <span *ngIf="isConnected" (click)="logOut()">Log Out</span>

</mat-toolbar>
<router-outlet></router-outlet>