<mat-card>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Nom à rechercher">
    </mat-form-field>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"  >
    
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nom </th>
            <td class="hrefLink" mat-cell *matCellDef="let poem; let i = index" ><a (click)="onViewPoem(i)">{{poem.title}}</a></td>
          </ng-container>
        
          <ng-container matColumnDef="categories">
            <th mat-header-cell *matHeaderCellDef> Catégorie </th>
            <td mat-cell *matCellDef="let poem"> {{poem.category}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let poem"> <button *ngIf="user" mat-icon-button style="color: crimson;" (click)="openDialog(poem)">
              <mat-icon>delete</mat-icon>
            </button> </td>
          </ng-container>

        
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


      <button class="btn btn-primary" (click)="onNewPoem()">Nouveau livre</button>

</table>
</mat-card>

<br><br>