<div class="content">
    <mat-card>
  <div>
   
      <div>
        <h5>{{ other.category }}</h5> <div class="modifyer"><mat-slide-toggle [checked]="isCheck" (change)="onChange()">Modifier</mat-slide-toggle></div>
      </div>
    
      <br>
  
      <!--AFFICHAGE STANDARD-->
      <div *ngIf="isCheck==false" class="padd">

        <div *ngIf="other.audio">
          <div class="audio"> 
          <mat-basic-audio-player [audioUrl]="audioSrc" [title]="msbapTitle" [autoPlay]="false" [startOffset]="startOffset"
          [displayTitle]="msbapDisplayTitle" [displayVolumeControls]="msbapDisplayVolumeControls" ></mat-basic-audio-player>
          </div>
          <br><br>
        </div>

        <h1>{{ other.title }}</h1>

        <div *ngIf="other.photo">
          <br>
          <img style="max-width:400px;" [src]="other.photo">
          <br>
        </div>
        <br>
        <br>
        <pre class="contentCore">{{ other.content }}</pre>
        <br>
        <br>
        <button mat-raised-button color="primary" (click)="onBack()">Retour</button> 
        <div class="modifyer">
        <!--<button mat-icon-button  style="color: crimson;" (click)="openDialog(poem)">
          <mat-icon>delete</mat-icon>
        </button>-->
      </div>
      </div>
      <!--SI MODIFICATION-->
      <div *ngIf="isCheck==true">

      <br>
      <h4>Photo</h4>
      <input type="file" (change)="detectFiles($event)"
             class="filePics" accept="image/*">
      <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
      <br>
      <br>
      <button  mat-raised-button color="warn" (click)="rmPics()">Clear Photo </button>
          <br>
          <br>
      <h4>Audio</h4>
      <input type="file" (change)="detectAudio($event)"
             class="filePics" accept="audio/*">
      <p class="text-success" *ngIf="audioUploaded">Fichier chargé !</p>
      <br>
      <br>
      <button  mat-raised-button color="accent" (click)="rmAudio()">Clear Audio </button>
      <br>
      <br>
      <br>
        <form [formGroup]="otherForm" (ngSubmit)="updateOther()" class="example-form">
          <mat-form-field class="example-full-width titleLimit mat-headline">
            <mat-label>Titre</mat-label>
            <input matInput  id="title"  formControlName="title">
          </mat-form-field>
          <br>
          <br>
          <mat-form-field class="example-full-width">
            <mat-label>Contenu</mat-label>
            <textarea matInput rows="20" cols="60"  id="content" formControlName="content"></textarea>
          </mat-form-field>
    
          <br>
          <button class="btn btn-success" [disabled]="otherForm.invalid"
                type="submit">Enregistrer
        </button>
  
        
        </form>
        <br>
  
      </div>
      
     
    </div>
  
  </mat-card>
  <br>
  <br>
  <br>
  <br>
  </div>
  
  
  