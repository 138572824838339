<div class="container0">
  <div class="leftPos">
<mat-card>
    <div>
        <div *ngIf="user">
           <div class="modifyer"><mat-slide-toggle [checked]="isCheck" (change)="onChange()">Modifier</mat-slide-toggle></div>
        </div>
      
        <!--AFFICHAGE STANDARD-->
        
        <div *ngIf="isCheck==false" class="padd">
    
          <h1>{{ skill.title }}</h1>
          
          <br><br>
          <img style="max-width:400px;" *ngIf="skill.photo" [src]="skill.photo">
      <br>
      <br>
          <pre class="contentCore">{{ skill.content }}</pre>
          <br><br>
          <button mat-raised-button color="primary" (click)="onBack()">Retour</button> 
          
          <div class="modifyer">
          <button mat-icon-button  style="color: crimson;" (click)="openDialog()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        </div>
        <!--SI MODIFICATION-->
        <div *ngIf="isCheck==true">
          <br>

          <h4>Photo</h4>
      <input type="file" (change)="detectFiles($event)"
             class="filePics" accept="image/*">
      <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
      <br>
      <br>
      <button  mat-raised-button color="warn" (click)="rmPics()">Clear Photo </button>
          <br>
          <br>
      <h4>Audio</h4>
      <input type="file" (change)="detectAudio($event)"
             class="filePics" accept="audio/*">
      <p class="text-success" *ngIf="audioUploaded">Fichier chargé !</p>
      <br>
      <br>
      <button  mat-raised-button color="accent" (click)="rmAudio()">Clear Audio </button>
      <br>
          <br>
          <br>
          <form [formGroup]="skillForm" (ngSubmit)="updateSkill()" class="example-form">
            <mat-form-field class="example-full-width titleLimit mat-headline">
              <mat-label>Titre</mat-label>
              <input matInput  id="title"  formControlName="title">
            </mat-form-field>
            <br>
            <br>
            <mat-form-field class="example-full-width">
              <mat-label>Contenu</mat-label>
              <textarea matInput rows="20" cols="60"  id="content" formControlName="content"></textarea>
            </mat-form-field>
      
            <br>
            <button class="btn btn-success" [disabled]="skillForm.invalid"
                  type="submit">Enregistrer
          </button>
    
          
          </form>
          <br>
    
        </div>
        
    </div>
    </mat-card>
  </div>
 
<div class="rightPos">

  <div class="audio" *ngIf="skill.audio">
    <mat-basic-audio-player [audioUrl]="audioSrc" [title]="msbapTitle" [autoPlay]="false" [startOffset]="startOffset"
    [displayTitle]="msbapDisplayTitle" [displayVolumeControls]="msbapDisplayVolumeControls" ></mat-basic-audio-player>

  </div>
  <div *ngIf="skill.audio">
    <br>

  </div>
  <mat-card *ngIf="user">
    Ajouter un fichier :
    <br>
    
    <app-upload-form [id]="id"></app-upload-form>
    </mat-card>
  <br>
    <mat-card>
      <div class="containerFiles">
      <div *ngIf="filess">

        <div *ngFor="let f of filess">

          <a [href]="f.url" target="_blank" rel="noopener">{{f.name}}   <button mat-icon-button  style="color: #3F51B5;">
            <mat-icon>get_app</mat-icon>
          </button></a> <button  *ngIf="user" mat-icon-button  style="color: crimson;" (click)="openDialogFile(f.name)">
            <mat-icon>delete</mat-icon>
          </button>
      </div>

      </div>
      <div *ngIf="filess.length<1">

        <a>No files yet</a>
      </div>
         

      </div>
           
    </mat-card>
</div>
</div>

    <br>
    <br>
    <br>
    <br>
    
